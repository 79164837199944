import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby";
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const Environment = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="働く環境｜About us｜株式会社スタートアッププラス" />

      <div className="block xl:flex justify-between">

        <div id="pageTitle" className="relative xl:fixed w-full xl:w-48 h-auto xl:h-full text-white bg-aboutusPage bg-no-repeat bg-center bg-cover  z-20 ">
          <h1 className="font-Copperplate text-center text-2xl sm:text-3xl py-10">
            About us
          </h1>
          <ul className="w-full text-center pb-5">
            <li className='mb-5'>
              <Link to="/aboutus-data" className=''>
                データで見るSUP
              </Link>
            </li>
            <li className='mb-5'>
              <Link to="/aboutus-environment" className=''>
                働く環境
              </Link>
            </li>
            <li className='mb-5'>
              <Link to="/aboutus-members" className=''>
                メンバー紹介
              </Link>
            </li>
          </ul>
        </div>

        <div id="main" className="static xl:ml-48 w-full">
          <div className=" w-11/12 max-w-[1000px] mx-auto py-10 sm:py-10">

            <section id="PageDescription" className="w-full mb-5 flex flex-wrap items-center justify-center lg:justify-evenly ">
              <h2 className="font-Copperplate text-center text-2xl sm:text-3xl leading-loose sm:leading-loose py-5">
                <span className="text-2xl sm:text-4xl ">スタートアッププラス</span>で<br />
                <span className="text-2xl sm:text-4xl ">全力</span>で<span className="text-3xl sm:text-5xl text-key-pink">働く</span>、<span className="text-2xl sm:text-4xl ">全力</span>で<span className="text-3xl sm:text-5xl text-key-pink">遊ぶ</span>
              </h2>
              <StaticImage src="../images/environment-top.png" className=" w-[400px] h-auto ml-3"/>
            </section>

            <section id="Environment" className="mb-20">
              <div className="border-l-8 border-key-sand mt-10 mb-5 py-2 pl-3">
                <h2 className="text-2xl">働く環境</h2>
                <p className="mt-2">エンジニアがパフォーマンスを最大限に発揮できるよう、働く環境を整えています。</p>
              </div>
              <div className="sm:flex flex-wrap justify-center md:justify-between">
                <div className=" w-full sm:w-1/2 md:w-1/3 p-3">
                  <StaticImage src="../images/EnvironmentImg1.png" className="w-full h-auto"/>
                  <h3 className="w-full text-xl text-center my-4">フラットで自由な社風</h3>
                  <p>
                    風通しがよく、声をあげればチャレンジできる環境なので、自身のアイデアを反映できるチャンスが多々あります。<br />
                    様々なことに対して柔軟ですが、その分セルフマネジメントが求められる環境でもあります。
                  </p>
                </div>
                <div className=" w-full sm:w-1/2 md:w-1/3 p-3 mb-5">
                  <StaticImage src="../images/EnvironmentImg2.png" className="w-full h-auto"/>
                  <h3 className="w-full text-xl text-center my-4">エンジニアスタッフ</h3>
                  <p>
                    少数精鋭で豊富な知識・経験があるスタッフが揃っています。<br />
                    ご入社後は先輩とのOJTを通じて技術やスキルを磨くことができます。<br />
                    入社時に実務経験が無かった社員も、今では頼りになるエンジニアに成長しています。
                  </p>
                </div>
                <div className=" w-full sm:w-1/2 md:w-1/3 p-3 mb-5">
                  <StaticImage src="../images/EnvironmentImg3.png" className="w-full h-auto"/>
                  <h3 className="w-full text-xl text-center my-4">スキルアップ制度</h3>
                  <p>
                    スタートアッププラスでは社員のスキルアップを積極的に応援しています。<br />
                    技術系の書籍購入補助、最新機種の検証機導入、資格取得・セミナー受講の支援などを行っています。
                  </p>
                </div>
                <div className=" w-full sm:w-1/2 md:w-1/3 p-3 mb-5">
                  <StaticImage src="../images/EnvironmentImg4.png" className="w-full h-auto"/>
                  <h3 className="w-full text-xl text-center my-4">高性能ノートPC</h3>
                  <p>
                  エンジニアがストレスを感じることなく開発に集中できるよう、高性能のノートPCを用意しています。<br />
                  ＜PCスペック例＞<small>※2021年度</small><br />
                  <small>◆MacBook Air（2020,M1チップ,16GBメモリ,512GB SSDストレージ）<br />
                  ◆MacBook Pro (13インチ, 2020, Intel Core i7プロセッサ,32GBメモリ,512GB SSDストレージ)</small>
                  </p>
                </div>
                <div className=" w-full sm:w-1/2 md:w-1/3 p-3 mb-5">
                  <StaticImage src="../images/EnvironmentImg5.png" className="w-full h-auto"/>
                  <h3 className="w-full text-xl text-center my-4">PC周辺機器</h3>
                  <p>
                  PC同様に、エンジニアがストレスなく作業できるよう、外部モニターやマウスなどの周辺機器も使用しやすいものを用意しており、希望があれば変更することも可能です。<br />
                  また、案件に応じてiPhone端末も支給しています。
                  </p>
                </div>
                <div className=" w-full sm:w-1/2 md:w-1/3 p-3 mb-5">
                  <StaticImage src="../images/EnvironmentImg6.png" className="w-full h-auto"/>
                  <h3 className="w-full text-xl text-center my-4">作業しやすい環境</h3>
                  <p>
                  ゆったりと落ち着いて作業することができるように広めのワークスペースと、長時間の作業でも体に負担の少ない高機能オフィスチェア（エルゴヒューマン・イングチェア）を用意しています。<br />
                  新しいオフィスも綺麗で、落ち着いた雰囲気です。
                  </p>
                </div>
              </div>
            </section>

            <section id="techData" className="mb-20">
              <div className="border-l-8 border-key-sand mt-10 mb-5 py-2 pl-3">
                <h2 className="text-2xl">使用実績言語・環境</h2>
                <p className="mt-2">顧客のニーズに応じ、多岐にわたるサービスを開発するうえで、新しい技術を取り入れつつ、最適な技術選定を行っています。</p>
              </div>
              <div className="sm:flex flex-wrap justify-center md:justify-between">
                <div className=" w-full sm:w-1/2 p-5 mb-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">使用している言語</h3>
                  <StaticImage src="../images/pgLanguageImg.png" className="w-full h-auto"/>
                </div>
                <div className=" w-full sm:w-1/2 p-5 mb-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-16">使用しているDB</h3>
                  <StaticImage src="../images/dbImg.png" className="w-full h-auto"/>
                </div>
                <div className=" w-full sm:w-1/2 p-5 mb-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">使用している技術・フレームワーク</h3>
                  <StaticImage src="../images/frameworkImg.png" className="w-full h-auto"/>
                </div>
                <div className=" w-full sm:w-1/2 p-5 mb-5">
                  <h3 className="fukidashi w-full px-2 py-4 text-xl text-center mb-8">使用しているインフラ</h3>
                  <StaticImage src="../images/infraImg.png" className="w-full h-auto"/>
                </div>
              </div>
            </section>

            <section id="office" className="mb-20">
              <div className="border-l-8 border-key-sand mt-10 mb-5 py-2 pl-3">
                <h2 className="text-2xl">オフィス紹介</h2>
                <p className="mt-2">移転したばかりのオフィスは、リフォーム済みで木目やシックな色合いが特徴的です。リラックスして仕事ができます。</p>
              </div>
              <div className="sm:flex flex-wrap justify-center md:justify-between">
                <div className=" w-full sm:w-1/2 md:w-1/3 p-3">
                  <StaticImage src="../images/officeImg1.png" className="w-full h-auto"/>
                  <p className="text-center">エントランス</p>
                </div>
                <div className=" w-full sm:w-1/2 md:w-1/3 p-3">
                  <StaticImage src="../images/officeImg2.png" className="w-full h-auto"/>
                  <p className="text-center">執務室への通路</p>
                </div>
                <div className=" w-full sm:w-1/2 md:w-1/3 p-3">
                  <StaticImage src="../images/officeImg3.png" className="w-full h-auto"/>
                  <p className="text-center">パーソナルロッカー</p>
                </div>
                <div className=" w-full sm:w-1/2 md:w-1/3 p-3">
                  <StaticImage src="../images/officeImg4.png" className="w-full h-auto"/>
                  <p className="text-center">執務室（入口から）</p>
                </div>
                <div className=" w-full sm:w-1/2 md:w-1/3 p-3">
                  <StaticImage src="../images/officeImg5.png" className="w-full h-auto"/>
                  <p className="text-center">執務室（ドリンクコーナーから）</p>
                </div>
                <div className=" w-full sm:w-1/2 md:w-1/3 p-3">
                  <StaticImage src="../images/officeImg6.png" className="w-full h-auto"/>
                  <p className="text-center">執務室（一番奥から）</p>
                </div>
                <div className=" w-full sm:w-1/2 md:w-1/3 p-3">
                  <StaticImage src="../images/officeImg7.png" className="w-full h-auto"/>
                  <p className="text-center">フリースペース</p>
                </div>
                <div className=" w-full sm:w-1/2 md:w-1/3 p-3">
                  <StaticImage src="../images/officeImg8.png" className="w-full h-auto"/>
                  <p className="text-center">会議室</p>
                </div>
                <div className=" w-full sm:w-1/2 md:w-1/3 p-3">
                  <StaticImage src="../images/officeImg9.png" className="w-full h-auto"/>
                  <p className="text-center">ドリンクコーナー</p>
                </div>
              </div>
            </section>

            <section id="event" className="mb-20">
              <div className="border-l-8 border-key-sand mt-10 mb-5 py-2 pl-3">
                <h2 className="text-2xl">社員旅行・社内イベント</h2>
                <p className="mt-2">国内・国外の社員旅行や、飲み会やBBQで語り合ったりして親睦を深めています。（※コロナ禍は旅行は中止）</p>
              </div>
              <div className="mb-5">
                <StaticImage src="../images/toyosuTitle.png" className="w-60 h-auto"/>
                <div className="">
                  <StaticImage src="../images/toyosu1.png" className="w-full sm:w-[220px] md:w-[200px] h-auto m-1"/>
                  <StaticImage src="../images/toyosu2.png" className="w-full sm:w-[250px] md:w-[220px] h-auto m-1"/>
                  <StaticImage src="../images/toyosu3.png" className="w-full sm:w-[250px] md:w-[220px] h-auto m-1"/>
                  <StaticImage src="../images/toyosu4.png" className="w-full sm:w-[250px] md:w-[220px] h-auto m-1"/>
                </div>
              </div>
              <div className="mb-5">
                <StaticImage src="../images/hokkaidoTitle.png" className="w-60 h-auto"/>
                <div className="">
                  <StaticImage src="../images/hokkaido1.png" className="w-full sm:w-[220px] md:w-[200px] h-auto m-1"/>
                  <StaticImage src="../images/hokkaido2.png" className="w-full sm:w-[250px] md:w-[220px] h-auto m-1"/>
                  <StaticImage src="../images/hokkaido3.png" className="w-full sm:w-[250px] md:w-[220px] h-auto m-1"/>
                  <StaticImage src="../images/hokkaido4.png" className="w-full sm:w-[250px] md:w-[220px] h-auto m-1"/>
                </div>
              </div>
              <div className="mb-5">
                <StaticImage src="../images/baliTitle.png" className="w-60 h-auto"/>
                <div className="">
                  <StaticImage src="../images/bali1.png" className="w-full sm:w-[220px] md:w-[200px] h-auto m-1"/>
                  <StaticImage src="../images/bali2.png" className="w-full sm:w-[250px] md:w-[220px] h-auto m-1"/>
                  <StaticImage src="../images/bali3.png" className="w-full sm:w-[250px] md:w-[220px] h-auto m-1"/>
                  <StaticImage src="../images/bali4.png" className="w-full sm:w-[250px] md:w-[220px] h-auto m-1"/>
                </div>
              </div>
              <div className="mb-5">
                <StaticImage src="../images/cairnsTitle.png" className="w-60 h-auto"/>
                <div className="">
                  <StaticImage src="../images/cairns1.png" className="w-full sm:w-[220px] md:w-[200px] h-auto m-1"/>
                  <StaticImage src="../images/cairns2.png" className="w-full sm:w-[250px] md:w-[220px] h-auto m-1"/>
                  <StaticImage src="../images/cairns3.png" className="w-full sm:w-[250px] md:w-[220px] h-auto m-1"/>
                  <StaticImage src="../images/cairns4.png" className="w-full sm:w-[250px] md:w-[220px] h-auto m-1"/>
                </div>
              </div>
              <p className="p-5 mb-10">
              上記で紹介した以外にも、不定期で飲み会やBBQ、屋形船での花見、最新のVR体験、テーマパークなどに行っています。<br />
                旅行の行き先はメンバーみんなで話し合いで決めるので、行ってみたい所に行けたり、希望していなかった所だとしても行ったらとても素晴らしい所だった！なんてこともあります。
              </p>
            </section>

            <section className="mb-20">
              <ul className="md:flex justify-evenly ">
                <li className="md:w-52 lg:w-72 border border-black p-2 mb-4">
                  <Link to="/recruit" className='block'>
                    <h3 className="font-Copperplate text-center text-2xl lg:text-3xl my-5">
                    RECRUIT
                    </h3>
                    <p className="text-center mb-5">
                      採用情報
                    </p>
                  </Link>
                </li>
                <li className="md:w-52 lg:w-72 border border-black p-2 mb-4">
                  <Link to="/aboutus-data" className='block'>
                    <h3 className="font-Copperplate text-center text-2xl lg:text-3xl my-5">
                    DATA
                    </h3>
                    <p className="text-center mb-5">
                      データで見る<br />スタートアッププラス
                    </p>
                  </Link>
                </li>
                <li className="md:w-52 lg:w-72 border border-black p-2 mb-4">
                  <Link to="/aboutus-members" className='block'>
                    <h3 className="font-Copperplate text-center text-2xl lg:text-3xl my-5">
                    MEMBERS
                    </h3>
                    <p className="text-center mb-5">
                    メンバー紹介
                    </p>
                  </Link>
                </li>
              </ul>
            </section>

          </div>
        </div>
      </div>

    </Layout>

    
  )
}



export default Environment

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

